/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */


/* Core CSS required for Ionic components to work properly */

@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */

@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* Custome css */
@import "assets/css/my-style.css";

.titre {
    text-align: center;
    letter-spacing: 0.1em;
    background-color: var(--ion-color-tertiary);
    width: 100%;
    height: 55px;
    padding: 1px;
}

.thumb {
    position: relative;
    width: 100%;
    height: auto;
}

.no-border-radius {
    border-radius: 0px;
    --border-radius: 0px;
}

.no-border-radius-bottom {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}

.error-message {
    color: var(--ion-color-danger);
}

.skeleton {
    width: 100%;
    height: 200px;
}

.skeleImage {
    width: 100%;
    height: 50vh
}

.btn {
    margin-top: 4%;
    text-align: center;
}

.spin {
    text-align: center;
}

.imageCard {
    width: 100%;
    display: block;
}

.Icon {
    margin-right: 20px;
    z-index: 100
}

.text_vide {
    text-justify: auto;
    text-align: center;
    font-weight: bolder;
}

.item-no-padding {
    --inner-padding-start: 0px;
    --padding-start: 8px;
    --padding-end: 0px;
    --inner-padding-end: 0px;
}

.text_conn {
    text-align: center;
    color: var(--ion-color-secondary);
    font-size: 20px;
    line-height: 2px
}

.entete_page {
    width: 100%;
    text-align: center;
    letter-spacing: 0.1em;
}

.text-description {
    width: 90%;
    margin-left: 5%;
}

.btn_offre {
    margin-top: 5%;
}

.img_offre {
    margin-top: 0;
    width: 50%;
    margin-left: 25%;
    image-rendering: pixelated;
}

.event-title {
    padding: 0px 16px;
    margin: 12px 0px;
}

.skeleton-event-content {
    margin-top: 7%;
    width: 90%;
    margin-left: 5%;
}

.skeleton-event-title {
    margin-top: 5%;
    width: 30%;
}

.skeleton-event-description {
    margin-top: 5%;
}

.skeleton-event-button {
    margin-top: 5%;
    width: 30%;
    height: 30px;
}

.skeleton-event-image {
    margin-top: 5%;
    height: 200px;
}

.card-footer {
    background-color: var(--ion-color-primary-contrast);
}

.transparent{
    background-color: #00000000 !important;
    --ion-background-color: #00000000 !important;
}