ion-button{
    border-radius: 0px;
    --border-radius: 0px;
}

ion-card{
    border-radius: 0px;
    box-shadow: unset;
    margin: 0px;
    background: var(--ion-color-light);
}

ion-content {
    --background: var(--ion-color-light);
}

.image_couleur{
    border-radius: 50px;
}

h1,h3, p{
    color : var(--ion-color-dark) !important;
}

.class_h4 h4{
    height: 34px;
    overflow: hidden;
}

.col-color{
    max-height: 22px;
    overflow: hidden;
}

.titre h3{
    color: var(--ion-color-dark);
}

.products-content app-product-card {
    width: 48%;
    margin-bottom: 5px;
    max-width: 240px;
}

.products-content ion-card {
    border: solid var(--ion-color-medium-tint) 1px;
}

.products-content ion-card ion-card-header {
    background: var(--ion-color-primary-contrast);
}


.class_btn {
    --background: transparent !important;
    color: var(--ion-color-primary) !important;
    border: solid 1px;
    border-radius: 5px !important;
    width: 50%;
    display: block;
    margin: 10px auto;
}

.class_btn .class_icon{
    background: transparent !important;
}

.class_price {
    margin-left: 0px !important;
    margin-top: 4px !important;
    text-align: center !important;
}

.div_col{
    height: 22px ;
}

.h4_name {
    margin-left: 0px !important;
    text-align: center;
    overflow: hidden;
}

@media (min-width: 768px) {
    .products-content app-product-card {
        width: 30%;
    }
}

.body-animation{
    --overflow: hidden;
    --background: #fff !important;
}


.body-animation img {
    margin-top: 30%;
}

.ban-title{
    position: relative !important;
    background: var(--ion-color-primary) !important;
}

app-product-card ion-card-content{
    text-align: center;    
}




